export const trimHtml = (html: any, options: any) => {
	options = options || {}

	let limit = options.limit || 100,
		preserveTags =
			typeof options.preserveTags !== 'undefined' ? options.preserveTags : true,
		wordBreak =
			typeof options.wordBreak !== 'undefined' ? options.wordBreak : false,
		suffix = options.suffix || '...',
		moreLink = options.moreLink || ''

	let remove_images = options.remove_images || false

	if (remove_images) {
		html = html.replace(/<img[^>]*>/g, '')
	}

	let arr = html
		.replace(/</g, '\n<')
		.replace(/>/g, '>\n')
		.replace(/\n\n/g, '\n')
		.replace(/^\n/g, '')
		.replace(/\n$/g, '')
		.split('\n')

	let sum = 0,
		row,
		cut,
		add,
		tagMatch,
		tagName,
		tagStack = [],
		more = false

	for (let i = 0; i < arr.length; i++) {
		row = arr[i]
		// count multiple spaces as one character
		let rowCut = row.replace(/[ ]+/g, ' ')

		if (!row.length) {
			continue
		}

		if (row[0] !== '<') {
			if (sum >= limit) {
				row = ''
			} else if (sum + rowCut.length >= limit) {
				cut = limit - sum

				if (row[cut - 1] === ' ') {
					while (cut) {
						cut -= 1
						if (row[cut - 1] !== ' ') {
							break
						}
					}
				} else {
					add = row.substring(cut).split('').indexOf(' ')

					// break on halh of word
					if (!wordBreak) {
						if (add !== -1) {
							cut += add
						} else {
							cut = row.length
						}
					}
				}

				row = row.substring(0, cut) + suffix

				if (moreLink) {
					row += '<a href="' + moreLink + '" style="display:inline">»</a>'
				}

				sum = limit
				more = true
			} else {
				sum += rowCut.length
			}
		} else if (!preserveTags) {
			row = ''
		} else if (sum >= limit) {
			tagMatch = row.match(/[a-zA-Z]+/)
			tagName = tagMatch ? tagMatch[0] : ''

			if (tagName) {
				if (row.substring(0, 2) !== '</') {
					tagStack.push(tagName)
					row = ''
				} else {
					while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
						tagStack.pop()
					}

					if (tagStack.length) {
						row = ''
					}

					tagStack.pop()
				}
			} else {
				row = ''
			}
		}

		arr[i] = row
	}

	return {
		html: arr.join('\n').replace(/\n/g, ''),
		more: more,
	}
}

export const stripTags = (html: string) => html.replace(/(<([^>]+)>)/ig, '');

export const cleanSpaces = (text: string) => {
	return text
		.replace(/&nbsp;/g, ' ')
		.replace(/[\t]/g, ' ')
		.replace(/[ ]{2,}/g, ' ')
		.replace(/[\s]+([\r\n])/g, '\r\n')
		.replace(/([\r\n])[\s]+/g, '\r\n')
		.replace(/[\r\n]{2,}/g, '\r\n')
}

export const findImagesFromHtml = (html: string) => {
	return html.match(/<img[^>]+src="([^">]+)">/);
}

export const normalizeCountForm = (number: number, words_arr: string[]) => {
	number = Math.abs(number)
	if (Number.isInteger(number)) {
		let options = [2, 0, 1, 1, 1, 2]
		return words_arr[
			number % 100 > 4 && number % 100 < 20
				? 2
				: options[number % 10 < 5 ? number % 10 : 5]
		]
	}
	return words_arr[1]
}
